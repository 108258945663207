:root {
    --tutorial-padding-left: 350px;
    --tutorial-padding-bottom: 300px;
  }

.hotspot1 {
position: absolute;
left: calc(var(--tutorial-padding-left) - 20px);
top: 16px;
}

.hotspot2 {
position: absolute;
left: 49%;
padding-left: calc(var(--tutorial-padding-left) / 2);
bottom: calc(var(--tutorial-padding-bottom) - 18px);
}

/** Default Chrome CSS */
.hotspot3 {
position: absolute;
left: 115px;
}

/** Edge specific CSS */
@supports (-ms-ime-align: auto) {
.hotspot3 {
    position: absolute;
    left: 115px;
    }
}

/** Safari specific CSS */
@supports (-webkit-hyphens:none) {
.hotspot3 {
    position: absolute;
    left: 50px;
    }
}

.hotspot4 {
position: absolute;
right: 241px; /** Use a hardcoded value from right since peference tab is always the 3rd from right. */
bottom: calc(var(--tutorial-padding-bottom) - 18px);
}

.toggle_expand_collapse button {
    padding: 4px;
}

/*
 * To make the toolbar on top of the iframe cover
 * TODO Put all the z-index in a single list in a single file to manage z-indexes (https://issues.amazon.com/issues/LOWA-9546)
 */
.floating_toolbar_wrapper {
    z-index: 2;
}

/*
 * react-draggable library adds .react-draggable-transparent-selection class
 * to body tag while an element is being dragged. We can use the class to
 * show/hide the cover div element
 * TODO Put all the z-index in a single list in a single file to manage z-indexes (https://issues.amazon.com/issues/LOWA-9546)
 */
.react-draggable-transparent-selection .draggable-iframe-cover {
    display: block;
    z-index: 1;
}

.draggable-iframe-cover {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.wsw-minimized-view {
    padding-bottom: 5px;
}

.svg-wrapper {
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
}

/*
 * We can't add custom design tokens to CloudScape. Instead, we will
 * update a class name of the embedded streaming component when a user
 * change the visual mode in preference.
 */
.dark-visual-mode .floating_toolbar {
    border: #5F6B7A solid 0.5px;
}
.light-visual-mode .floating_toolbar {
    border: #E6E6E6 solid 0.5px;
}
.dark-visual-mode .floating_handle {
    background-color: #5F6B7A;
}
.light-visual-mode .floating_handle {
    background-color: #E6E6E6;
}

.application.icon {
    height: 30px;
    width: 30px;
    padding-top: 6px;
}

.application.label {
    line-height: 42px;
    font-size: 14px;
}

.with-expand-button .toolbar-button {
    padding: 2px 1px 0 1px;
}

.expand-dropdown-button-icon {
    position: absolute;
}

/*
    MuiSvgIcon-fontSizeInherit is a MUI svg icon class name for inherit option.
    (https://mui.com/material-ui/api/svg-icon/?srsltid=AfmBOopAH_4ZfkFFrTX4FRV88wIK1lxrc5N0IZc0iEH8sau1bFad4MZh#classes)
*/
.expand-dropdown-button-icon svg.MuiSvgIcon-fontSizeInherit {
    font-size: 19px;
    left: -5px;
    top: -17px;
    position: relative;
}

.with-expand-button .toolbar-item-group-label-container {
    padding: 0;
}

.with-expand-button .dropdown-button {
    position: absolute;
    height: 42px;
    width: 0;
    padding: 0;
}